import React from 'react';
import { useCookies } from 'react-cookie';

function Header({ header, onButtonClick }) {
  const [cookies, setCookie, removeCookie] = useCookies(null)
  const userEmail = cookies.Email
  const userName = cookies.Name

  function signOut() {
    const isConfirmed = window.confirm('Are you sure you want to Sign Out?');
    if (isConfirmed) {
    console.log('sign-out');
    removeCookie('Email')
    removeCookie('AuthToken')
    localStorage.clear()
    window.location.reload()
    }
  }

  return (
    <nav className="navbar navbar-light bg-light">
    <div className="container-fluid d-flex justify-content-between align-items-center">
      <span className="navbar-brand" style={{ fontSize: '2rem' }}>
        {header}
      </span>
      {userName ? (
        <span className="user-name">
          <strong>{userName}</strong>
        </span>
      ) : (
        <span className="user-email">
          {userEmail}
        </span>
      )}

      <div className="button-container">
        <button className="btn btn-light" onClick={() => onButtonClick('About')}>
          About
        </button>
        <button className="btn btn-light" onClick={() => onButtonClick('Book Search')}>
          Library
        </button>
        <button className="btn btn-light" onClick={() => onButtonClick('Notes')}>
          Log
        </button>
        <button className="btn btn-light sign-out" onClick={signOut}>
          Sign Out
        </button>
      </div>
    </div>
  </nav>
  
  );
}

export default Header;
