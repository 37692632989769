import React, { useEffect, useState } from "react";
import { getBookCover } from "../services/apiService";

const DEFAULT_COVER_URL = "https://picsum.photos/128/192";

const BookGrid = ({ books }) => {
  const [coverUrls, setCoverUrls] = useState([]);

  useEffect(() => {
    // Fetch cover URLs for all books
    const promises = books.map((book) => getBookCover(book.book_isbn));

    // Wait for all promises to resolve
    Promise.all(promises)
      .then((urls) => setCoverUrls(urls))
      .catch((error) => console.error("Error fetching cover URLs:", error));
  }, [books]);

  const handleTileClick = (bookISBN) => {
    const selectedBook = books.find((book) => book.book_isbn === bookISBN);
    localStorage.setItem("lastSelectedBook", JSON.stringify(selectedBook));
    localStorage.setItem("selectedOption", "Notes");
    window.location.reload();
  };

  return (
    <div className="mt-2">
      <br></br>
      {books && books.length > 0 ? (
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-4 mx-auto">
          {books.map((book, index) => (
            <div key={book.book_isbn} className="col mb-4">
              <div
                onClick={() => handleTileClick(book.book_isbn)}
                className="card h-100 text-center mx-auto"
                style={{ maxWidth: "150px", cursor: "pointer" }}
              >
                <img
                  src={coverUrls[index] || DEFAULT_COVER_URL}
                  className="card-img-top mx-auto"
                  alt={book.title}
                  style={{
                    width: "100%",
                    height: "150%",
                    objectFit: "cover",
                    marginTop: "auto",
                  }}
                  onError={() =>
                    console.error(`Error loading thumbnail for ${book.title}`)
                  }
                />
                <div className="card-body mx-auto">
                  <h6 className="card-title">{book.title}</h6>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center">
          Your library is empty - search for books to add.
        </p>
      )}
    </div>
  );
};

export default BookGrid;
