import instance from "../index";
import axios from "axios";
const baseURL =
  process.env.NODE_ENV === "development"
    ? `${process.env.REACT_APP_SERVER_URL_DEV}/api`
    : "/api";

export function getBooks(email) {
  return instance
    .post(`${baseURL}/book/get-books`, { email })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("getBooks error:", error);
      return Promise.reject(error);
    });
}

export function getNotes(bookIsbn, email) {
  return instance
    .post(`${baseURL}/notes/get-notes`, {
      email: email,
      book_isbn: bookIsbn,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("getNotes error:", error);
      return Promise.reject(error);
    });
}

export function saveNoteToDatabase(book_isbn, newNote, email) {
  const currentDate = new Date();

  return instance
    .post(`${baseURL}/notes/add-note`, {
      book_isbn: book_isbn,
      email: email,
      date: currentDate,
      notes: newNote,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("saveNoteToDatabase error:", error);
      if (error.response) {
        console.error("Status:", error.response.status);
      }
      return Promise.reject(error);
    });
}

export async function getBookCover(isbn) {
  const url = `https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}`;
  const cacheKey = `bookCover_${isbn}`;
  const cachedImage = localStorage.getItem(cacheKey);

  if (cachedImage) {
    return Promise.resolve(cachedImage);
  }

  try {
    const response = await axios.get(url);
    const items = response.data.items;

    if (items && items.length > 0) {
      const bookData = items[0];

      if (bookData && bookData.volumeInfo.imageLinks) {
        let thumbnail = bookData.volumeInfo.imageLinks.thumbnail;

        if (thumbnail) {
          if (thumbnail.startsWith("http://")) {
            thumbnail = thumbnail.replace(/^http:/, "https:");
          }
          localStorage.setItem(cacheKey, thumbnail);
          return thumbnail;
        } else {
          console.warn(`Thumbnail is undefined for book with ISBN ${isbn}`);
          return null;
        }
      } else {
        console.warn(
          `ImageLinks or volumeInfo is undefined for book with ISBN ${isbn}`
        );
        return null;
      }
    } else {
      console.warn(`No items found for book with ISBN ${isbn}`);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching book cover for ISBN ${isbn}:`, error);
    return null;
  }
}

export function getBookAuthor(isbn) {
  const url = `https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}`;
  return axios
    .get(url)
    .then((response) => {
      const bookData = response.data.items[0];
      if (bookData && bookData.volumeInfo.authors) {
        return bookData.volumeInfo.authors[0];
      } else {
        return null;
      }
    })
    .catch((error) => Promise.reject(error));
}

export const updateNoteById = async (notes_id, updatedNote, email) => {
  try {
    const response = await instance.post(`${baseURL}/notes/update-note`, {
      notes_id,
      note_update: updatedNote,
      email,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteNoteById = async (notes_id, email) => {
  try {
    const response = await instance.post(`${baseURL}/notes/delete-note`, {
      notes_id,
      email,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeBook = async (isbn, email) => {
  try {
    // Make both API calls concurrently using Promise.all
    const [book_response, notes_response] = await Promise.all([
      instance.post(`${baseURL}/book/delete-book`, {
        isbn,
        email,
      }),
      instance.post(`${baseURL}/notes/delete-all-book-notes`, {
        isbn,
        email,
      }),
    ]);
    localStorage.removeItem("lastSelectedBook");
    window.location.reload();

    return {
      book_response: {
        status: book_response.status,
      },
      notes_response: {
        status: notes_response.status,
      },
    };
  } catch (error) {
    const bookError = error.response && {
      status: error.response.status,
      data: error.response.data,
    };

    const notesError = error.response && {
      status: error.response.status,
      data: error.response.data,
    };

    return { bookError, notesError } || error;
  }
};

export function emailNotes(isbn, title, author, email, recipientEmail) {
  const postData = {
    isbn,
    title,
    author,
    email,
    recipientEmail,
  };

  return instance
    .post(`${baseURL}/notes/email-notes`, postData)
    .then((response) => {
      console.log("Email notes response:", response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error sending email notes:", error);
      return Promise.reject(error);
    });
}
