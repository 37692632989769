import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const baseURL =
  process.env.NODE_ENV === "development"
    ? `${process.env.REACT_APP_SERVER_URL_DEV}/api`
    : "/api";

function Auth() {
  const [cookies, setCookie, removeCookie] = useCookies(null);
  const [isLogIn, setIsLogin] = useState(true);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [name, setName] = useState(null); // Add state for the user's name
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isForgotPassword, setIsForgotPassword] = useState(null);

  const viewLogin = (status) => {
    setError(null);
    setIsLogin(status);
  };

  const handleForgotPassword = () => {
    if (isForgotPassword) {
      setIsForgotPassword(false);
    } else {
      setIsForgotPassword(true);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const isValidEmail = validateEmail(email);

    if (!isValidEmail) {
      setError("Please enter a valid email address.");
      setTimeout(() => setError(""), 5000);
    } else {
      try {
        setError("");

        const response = await fetch(`${baseURL}/auth/request-password-reset`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        });
        if (response.status === 200) {
          setMessage(
            `A password reset link has been sent to ${email}. Please check your inbox.`
          );
        } else if (response.status === 500) {
          setError("An unexpected error occurred. Please email Claude.");
        } else if (response.status === 404) {
          setError("An account with this email doesn't exist.");
        } else {
          // Handle other error cases
          console.error("Unexpected error:", response.statusText);
          setError("An unexpected error occurred. Please email Claude.");
        }

        setTimeout(() => {
          setMessage("");
          setIsForgotPassword(false); // Redirect to login page after 5 seconds
        }, 5000);
      } catch (error) {
        // Handle unexpected errors
        console.error("Unexpected error:", error);
        setError("An unexpected error occurred. Please try again later.");
      }
    }
  };

  const handleSignUp = () => {
    // Combine setIsForgotPassword(false) and () => viewLogin(false)
    setIsForgotPassword(false);
    viewLogin(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    if (password == null) {
      return "Password is required.";
    }

    // Define password requirements
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);

    // Check each requirement
    if (!(password.length >= 8 && password.length <= 24)) {
      return "Password must be between 8 -24 characters long.";
    }

    if (!hasDigit) {
      return "Password must contain at least one digit.";
    }

    if (!hasSpecialChar) {
      return "Password must contain at least one special character.";
    }

    // If all requirements are met, the password is valid
    return null;
  };

  const handleSubmit = async (e, endpoint) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setTimeout(() => setError(""), 5000);
      return;
    }

    const validationError = validatePassword(password);

    if (validationError) {
      setError(validationError);
      setTimeout(() => setError(""), 5000);
      return;
    }

    if (!isLogIn && !name) {
      setError("Please fill in all required fields.");
      setTimeout(() => setError(""), 5000);
      return;
    }

    if (!isLogIn && !password) {
      setError("Please fill in all required fields.");
      setTimeout(() => setError(""), 5000);
      return;
    }

    if (!isLogIn && password !== confirmPassword) {
      setError("Make sure passwords match!");
      setTimeout(() => setError(""), 5000);
      return;
    }

    // Include the user's name in the request body only when signing up
    const requestBody = isLogIn
      ? { email, password }
      : { email, password, name };

    try {
      const response = await fetch(`${baseURL}/auth/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.ok) {
        // Set cookies only if the response contains a valid token
        if (data.token) {
          setCookie("AuthToken", data.token, { secure: true });
          setCookie("Email", data.email, { secure: true });
          setCookie("Name", data.name, { secure: true });
        } else {
          setError("Incorrect email or password. Please try again.");
          setTimeout(() => setError(""), 5000);
          removeCookie("AuthToken");
          removeCookie("Email");
          removeCookie("Name");
          // Delay the reload for 3 seconds
          // setTimeout(() => window.location.reload(), 3000);
        }
      } else {
        console.log("Error Response:", response);
        if (response.status === 401) {
          setError("Incorrect email or password. Please try again.");
          setTimeout(() => setError(""), 5000);
        } else {
          setError(data.detail || "An error occurred!");
          setTimeout(() => setError(""), 5000);
        }
        removeCookie("AuthToken");
        removeCookie("Email");
        removeCookie("Name");
        //setTimeout(() => window.location.reload(), 5000);
      }
    } catch (error) {
      console.error("Error during fetch:", error);
      setError("An unexpected error occurred.");
      setTimeout(() => setError(""), 5000);
      removeCookie("AuthToken");
      removeCookie("Email");
      removeCookie("Name");
      //setTimeout(() => window.location.reload(), 5000);
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-container-box">
        <form>
          <h3 className="mb-4">Welcome to LitLogs</h3>
          <h4>
            {isForgotPassword
              ? "Password Reset"
              : isLogIn
              ? "Log In"
              : "Sign Up"}
          </h4>
          <br />

          {!isForgotPassword && !isLogIn && (
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          )}

          <div className="mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {!isLogIn && email && (
            <div style={{ marginLeft: "10px" }}>
              Valid Email:{" "}
              {!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ? (
                <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
              ) : (
                <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
              )}
            </div>
          )}

          {!isLogIn && email && <br></br>}

          {!isForgotPassword && (
            <div className="mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          )}

          {!isLogIn && password && (
            <div style={{ marginLeft: "10px" }}>
              8-24 Characters Long:{" "}
              {!(password.length >= 8 && password.length <= 24) ? (
                <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
              ) : (
                <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
              )}
            </div>
          )}

          {!isLogIn && password && (
            <div style={{ marginLeft: "10px" }}>
              Has Number:{" "}
              {!/\d/.test(password) ? (
                <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
              ) : (
                <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
              )}
            </div>
          )}

          {!isLogIn && password && (
            <div style={{ marginLeft: "10px" }}>
              Has Special Character:{" "}
              {!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password) ? (
                <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
              ) : (
                <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
              )}
            </div>
          )}

          {!isLogIn && password && <br></br>}

          {!isForgotPassword && !isLogIn && (
            <div className="mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          )}

          {!isLogIn && password && confirmPassword && (
            <div style={{ marginLeft: "10px" }}>
              Passwords Match:{" "}
              {!(password === confirmPassword) ? (
                <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
              ) : (
                <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
              )}
            </div>
          )}

          {!isLogIn && password && confirmPassword && <br></br>}

          {isForgotPassword ? (
            // Forgot Password section
            <div className="mb-3">
              <button
                type="button"
                className="btn btn-link"
                onClick={handleForgotPassword}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleResetPassword}
              >
                Reset Password
              </button>
            </div>
          ) : (
            // Log In or Sign Up section
            <div className="mb-3">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={(e) => handleSubmit(e, isLogIn ? "login" : "signup")}
              >
                {isLogIn ? "Log In" : "Sign Up"}
              </button>
            </div>
          )}

          {!isForgotPassword && isLogIn && (
            <div className="mb-3">
              <button
                type="button"
                className="btn btn-link"
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </button>
            </div>
          )}

          {error && <p className="text-danger">{error}</p>}
          {message && (
            <div className="text-success">
              <p>{message}</p>
            </div>
          )}
        </form>

        <div className="auth-options">
          <button
            onClick={handleSignUp}
            className={`btn ${isLogIn ? "btn-light" : "btn-secondary"}`}
          >
            Sign Up
          </button>

          <button
            onClick={() => viewLogin(true)}
            className={`btn ${isLogIn ? "btn-secondary" : "btn-light"}`}
          >
            Log In
          </button>

          <br></br>
          <br></br>

          <div id="content">
            <h4 id="what-is-it">What is it?</h4>
            <p>
              This little site has one purpose - capturing those 'aha' moments,
              favorite quotes, and spontaneous reflections on the books you
              read.
            </p>
            <br></br>

            <h4 id="why">Why?</h4>
            <p>
              Someone mentions a book you should read? Add it to your library.
            </p>
            <p>
              Want to recommend a book to others? Just hit 'Send to a Friend' to
              share your notes.
            </p>
            <p>
              Listening to an audiobook? Seamlessly log your notes on the go.
            </p>
            <br></br>

            <h4 id="a-word-on-privacy">A word on Privacy</h4>
            <p>Privacy is my top priority:</p>
            <ul>
              <li>Your notes are encrypted so that only you can see them.</li>
              <li>
                Only you can see the contents of emails to yourself and friends.
              </li>
              <li>
                All notes are exportable to .txt files. You can delete your data
                whenever you like.
              </li>
            </ul>

            <br></br>

            <h4 id="who-is-behind-this">Who's behind this?</h4>
            <p>
              That's me - <a href="https://claudemulindi.com">Claude</a>. I made
              this for myself but thought it could be helpful to others.
            </p>
          </div>

          <div>
            <img
              src="litlogs_demo_search.jpeg"
              alt="image not found"
              loading="lazy"
              width="300"
              style={{ padding: "20px" }}
            />
            <img
              src="litlogs_demo_notes.jpeg"
              alt="image not found"
              loading="lazy"
              width="300"
              style={{ padding: "20px" }}
            />
            <img
              src="litlogs_demo_export.jpeg"
              alt="image not found"
              loading="lazy"
              width="300"
              style={{ padding: "20px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auth;
