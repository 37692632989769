import React, { useState, useEffect } from "react";
import instance from "../index";
import { useCookies } from "react-cookie";
import { getBooks, removeBook } from "../services/apiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import BookGrid from "./BookGrid";
import { getBookCover } from "../services/apiService";

const baseURL =
  process.env.NODE_ENV === "development"
    ? `${process.env.REACT_APP_SERVER_URL_DEV}/api`
    : "/api";

const BookSearch = () => {
  const [cookies] = useCookies(null);
  const userEmail = cookies.Email;
  const [bookList, setBookList] = useState([]);
  const [bookDetails, setBookDetails] = useState("");
  const [selectedBookId, setSelectedBookId] = useState("");
  const [selectedBook, setSelectedBook] = useState("");
  const [libraryMessage, setLibraryMessage] = useState("");
  const [books, setBooks] = useState([]);
  const authToken = cookies.AuthToken;
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const booksData = await getBooks(userEmail);
        setBooks(booksData || []);
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    };

    fetchBooks();
  }, [userEmail]);

  const onChange = (value) => {
    setSearchQuery(value);
    if (value.trim() !== "") {
      searchBooks(value);
    } else {
      setBookList([]); // Clear the search results when input is empty
    }
    //console.log(searchQuery)
  };

  useEffect(() => {
    // Check if searchQuery is not empty before making the API call
    if (searchQuery.trim() !== "") {
      const delayDebounceFn = setTimeout(() => {
        // pass the latest searchQuery
        searchBooks(searchQuery);
      }, 500); // Delay

      // Cleanup function to clear the timeout when the searchQuery changes
      return () => clearTimeout(delayDebounceFn);
    }
    //console.log(searchQuery)
  }, [searchQuery]);

  const searchBooks = async (searchTerm) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/books/v1/volumes?q=${searchTerm}`
      );
      const data = await response.json();
      //console.log('Data from API:', data);

      if (data.items) {
        const books = data.items.map((item) => ({
          id: item.volumeInfo.industryIdentifiers[0].identifier,
          title: item.volumeInfo.title,
          author: item.volumeInfo.authors
            ? item.volumeInfo.authors.join(", ")
            : "N/A",
          thumbnail: item.volumeInfo.imageLinks
            ? item.volumeInfo.imageLinks.thumbnail
            : null,
          publishedDate: item.volumeInfo.publishedDate
            ? item.volumeInfo.publishedDate
            : "N/A",
          description: item.volumeInfo.description
            ? item.volumeInfo.description
            : "N/A",
        }));

        const results = books.filter((book) => {
          //return book && book.title && book.title.toLowerCase().includes(searchTerm.toLowerCase());
          return book && book.title;
        });
        setBookList(results);
        //console.log('Filtered Results:', results);
        //console.log('Book List:', results);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleBookSelection = (selectedBookId) => {
    setSelectedBookId(selectedBookId);
    const selectedBook = bookList.find((book) => book.id === selectedBookId);
    setSelectedBook(selectedBook);
    setSearchQuery("");
    setBookList([]);
    if (selectedBook) {
      const bookDetailsHTML = `
        ${
          selectedBook.thumbnail
            ? `<img src="${selectedBook.thumbnail}" alt="${selectedBook.title} cover" style="max-width: 200px; max-height: 300px;" />`
            : ""
        }
        <p>Title: ${selectedBook.title}</p>
        <p>Author: ${selectedBook.author}</p>
        <p>Date Published: ${selectedBook.publishedDate}</p>
        <p>Description: ${selectedBook.description}</p>
        <p>ISBN: ${selectedBook.id}</p>
      `;

      setBookDetails(bookDetailsHTML);
      setLibraryMessage("");
      //console.log(selectedBook)
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchBooks(searchQuery);
    }
  };

  const addToLibrary = (selectedBookId) => {
    //console.log('Selected Book ID:', selectedBookId);
    if (selectedBookId) {
      //const selectedBook = bookList.find((book) => book.id === selectedBookId);
      //console.log(selectedBook)
      //console.log(userEmail)
      //console.log(bookList)
      if (userEmail && selectedBook) {
        const bookToAdd = {
          isbn: selectedBook.id,
          title: selectedBook.title,
          author: selectedBook.author,
          email: userEmail,
        };

        instance
          .post(`${baseURL}/book/add-book`, bookToAdd)
          .then(async (response) => {
            if (response.status >= 200 && response.status < 300) {
              const updatedBooksData = await getBooks(userEmail);
              setBooks(updatedBooksData || []);

              setLibraryMessage(
                `Added "${bookToAdd.title}" to your library. You're ready to log notes.`
              );
              // REset search query
              setSearchQuery("");
            } else {
              if (response.status === 400) {
                throw new Error(
                  "There's a problem saving your books for your profile. Please reach out to Claude."
                );
              } else if (response.status === 409) {
                throw new Error(
                  `ISBN ${bookToAdd.isbn} is already in your Reading Log`
                );
              } else {
                throw new Error(`Server error for ISBN ${bookToAdd.isbn}`);
              }
            }
          })
          .catch((error) => {
            console.error("Error adding the book:", error);
            setLibraryMessage(`Error adding the book. ${error.message}`);
          });
      }
    }
  };

  return (
    <div className="module-containers">
      <br />

      <div
        className="position-relative d-inline-block"
        style={{ width: "100%" }}
      >
        <input
          type="text"
          id="searchInput"
          className="form-control booksearch-box"
          placeholder="🔍 Add to your library - search for any book title or author"
          onKeyPress={handleKeyPress}
          value={searchQuery}
          onChange={(e) => onChange(e.target.value)}
        />

        {/*console.log('Rendering Book List:', bookList)*/}
        {bookList.length > 0 && searchQuery && (
          <div className="search-results-dropdown">
            {bookList.map((book) => (
              <div
                key={book.id}
                className="search-result-item"
                onClick={() => handleBookSelection(book.id)}
              >
                {book.title} - {book.author}
              </div>
            ))}
          </div>
        )}
      </div>

      {selectedBookId && (
        <div>
          <div id="bookDetails">
            <br></br>
            <h4>{selectedBook.title}</h4>
            <h5>{selectedBook.author}</h5>
            <p>{selectedBook.publishedDate}</p>
            <p>
              <img src={selectedBook.thumbnail} alt="Book Thumbnail" />
            </p>
            <p>{selectedBook.description}</p>
            <p>ISBN: {selectedBookId}</p>
          </div>
          <br />
          <button
            className="btn btn-primary"
            onClick={() => addToLibrary(selectedBookId)}
            id="addToLibrary"
          >
            Add to Library
          </button>
        </div>
      )}
      <br></br>
      <br></br>
      {libraryMessage && (
        <p className="font-weight-bold">
          <strong>{libraryMessage}</strong>
        </p>
      )}
      <BookGrid books={books} getBookCover={getBookCover} />
    </div>
  );
};
export default BookSearch;
