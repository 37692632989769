import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const baseURL =
  process.env.NODE_ENV === "development"
    ? `${process.env.REACT_APP_SERVER_URL_DEV}/api`
    : "/api";

const PasswordReset = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [error, setError] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(null);
  const [message, setMessage] = useState(null);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    if (password == null) {
      return "Password is required.";
    }

    // Define password requirements
    const minLength = 8;
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);

    // Check each requirement
    if (password.length < minLength) {
      return "Password must be at least 8 characters long.";
    }

    if (!hasLowerCase) {
      return "Password must contain at least one lowercase letter.";
    }

    if (!hasUpperCase) {
      return "Password must contain at least one uppercase letter.";
    }

    if (!hasDigit) {
      return "Password must contain at least one digit.";
    }

    if (!hasSpecialChar) {
      return "Password must contain at least one special character.";
    }

    // If all requirements are met, the password is valid
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    const validationError = validatePassword(password);

    if (validationError) {
      setError(validationError);
      setTimeout(() => setError(""), 5000);
      return;
    }

    if (!password) {
      setError("Please fill in all required fields.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Make sure passwords match!");
      return;
    }

    const requestBody = {
      email,
      password,
    };

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");

    if (token) {
      requestBody.token = token;
    }

    const handleCleanup = () => {
      setTimeout(() => {
        setError("");
        setMessage("");
        removeCookie("AuthToken");
        removeCookie("Email");
        queryParams.delete("token"); // Remove the token from the URL
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        ); // Remove 'reset-password?' from the URL
        window.location.href = window.location.origin;
      }, 5000);
    };

    try {
      const response = await fetch(`${baseURL}/auth/reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.status === 200) {
        // Set cookies only if the response contains a valid token
        if (data.token) {
          console.log("Password Reset!");
          setMessage("Successfully reset your password");
          setTimeout(() => {
            setCookie("AuthToken", data.token, { secure: true });
            setCookie("Email", data.email, { secure: true });
            setMessage(""); // Clear the success message after setting cookies
            queryParams.delete("token"); // Remove the token from the URL
            window.history.replaceState(
              {},
              document.title,
              window.location.pathname
            ); // Remove 'reset-password?' from the URL
            window.location.href = window.location.origin;
          }, 3000);
        } else {
          setError("Could not reset your password. Please try again.");
          handleCleanup();
        }
      } else {
        console.log("Error Response:", response);

        let errorMessage = "";

        if (response.status === 404) {
          errorMessage =
            "An account with this email does not exist. Please contact Claude.";
        } else if (response.status === 403) {
          errorMessage = "Password is required.";
        } else if (response.status === 401) {
          errorMessage =
            "Password reset link has expired. Please request a new one.";
        } else if (response.status === 406) {
          errorMessage = "This isn't your account!";
        } else {
          errorMessage =
            "Could not reset your password. Please try again. " +
            (data.detail || "");
        }

        setError(errorMessage);
        handleCleanup();
      }
    } catch (error) {
      console.error("Error during fetch:", error);
      setError("An unexpected error occurred.");
      handleCleanup();
    }
  };

  return (
    <div className="auth-container">
      <h2 className="mb-4">LitLogs</h2>
      <h3 className="mb-4">Reset your password</h3>
      <p className="mb-3">Quick! This link expires in 5 minutes.</p>
      <form>
        <div className="mb-3">
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {password && (
          <div style={{ marginLeft: "10px" }}>
            8-24 Characters Long:{" "}
            {!(password.length >= 8 && password.length <= 24) ? (
              <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
            ) : (
              <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
            )}
          </div>
        )}

        {password && (
          <div style={{ marginLeft: "10px" }}>
            Has Number:{" "}
            {!/\d/.test(password) ? (
              <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
            ) : (
              <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
            )}
          </div>
        )}

        {password && (
          <div style={{ marginLeft: "10px" }}>
            Has Special Character:{" "}
            {!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password) ? (
              <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
            ) : (
              <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
            )}
          </div>
        )}

        {password && <br></br>}

        <div className="mb-3">
          <input
            type="password"
            className="form-control"
            placeholder="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>

        {password && confirmPassword && (
          <div style={{ marginLeft: "10px" }}>
            Passwords Match:{" "}
            {!(password === confirmPassword) ? (
              <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
            ) : (
              <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
            )}
          </div>
        )}

        {password && confirmPassword && <br></br>}

        <div className="mb-3">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Reset Password!
          </button>
        </div>

        {error && <p className="text-danger">{error}</p>}
        {message && (
          <div className="text-success">
            <p>{message}</p>
          </div>
        )}
      </form>
    </div>
  );
};

export default PasswordReset;
