import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import Header from './components/Header';
import BookSearch from './components/BookSearch';
import NoteTakingPage from './components/NoteTakingPage';
import About from './components/About';
import Auth from './components/Auth';
import PasswordReset from './components/PasswordReset';

function App() {
  const [cookies] = useCookies(['AuthToken', 'Email']);
  const authToken = cookies.AuthToken;

  const [selectedOption, setSelectedOption] = useState(() => {
    const storedOption = localStorage.getItem('selectedOption');
    return storedOption || 'About';
  });

  // Function to check for the presence of a password reset token in the URL
  const hasResetToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has('token') && urlParams.get('token').trim() !== '';
  };

  const resetToken = hasResetToken();

  useEffect(() => {
    if (authToken) {
      const storedOption = localStorage.getItem('selectedOption');
      setSelectedOption(storedOption || 'About');
    }
  }, [authToken]);

  useEffect(() => {
    // Listen for changes in local storage and update the selectedOption
    const handleStorageChange = () => {
      const storedOption = localStorage.getItem('selectedOption');
      setSelectedOption(storedOption || 'About');
    };

    window.addEventListener('storage', handleStorageChange);

    // Clean up the event listener
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleButtonClick = (option) => {
    setSelectedOption(option);
    localStorage.setItem('selectedOption', option);
  };

  return (
    <div className="container app mt-5">
      {authToken ? (
        <div>
          <Header header={'LitLogs'} onButtonClick={handleButtonClick} />
          {selectedOption === 'Book Search' && <BookSearch />}
          {selectedOption === 'Notes' && <NoteTakingPage />}
          {selectedOption === 'About' && <About />}
        </div>
      ) : resetToken ? (
        <PasswordReset />
      ) : (
        <Auth />
      )}
    </div>
  );
}

export default App;
